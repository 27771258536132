import '../base';

// import $ from "jquery";
import 'slick-carousel';

$(function() {
  $('#amenities-carousel').slick({
    slidesToShow: 3,
    // slidesPerRow: 4,
    slidesToScroll: 1,
    rows: 0,
    nextArrow: buildButton('icon-arrows-right', 'next'),
    prevArrow: buildButton('icon-arrows-left', 'prev'),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});

const themeDirectory = '/wp-content/themes/cassara-theme';
const spriteSheet = 'assets/icon-defs.svg';
function buildButton(icon, control) {
  const label =
    control === 'next' ? 'Go back one amenity' : 'Go forward one amenity';

  if (isIE()) {
    // const arrow = control === "next" ? `&rarr;` : `&larr;`;
    const style = `
      background-image: url(wp-content/themes/cassara-theme/assets/SVG/${icon.replace(
        'icon-',
        ''
      )}.svg);
      background-position: center;
      background-repeat: no-repeat;
      width: 4rem;
      height: 4rem;
    `;
    return `
      <button class="slick-arrow__${control}" aria-label="${label}" style="${style} ">
      </button>
    `;
  }
  return `
      <button class="slick-arrow__${control}" aria-label="${label}">
        <svg class="slick-arrow__icon ie_icon" data-icon="icon" "data-size="3">
          <use href="${themeDirectory}/${spriteSheet}#${icon}"></use>
        </svg>
      </button>
    `;
}

// function handleBulletins() {
//   const containers = $(".bulletin__wrapper");
//   containers.each((index, el) => {
//     const close = $(el).find(".bulletin__close");
//     close.on("keydown", e => {
//       if (e.key === "Enter") {
//         remove(el);
//       }
//     });
//     close.on("click", () => remove(el));
//   });

//   function remove(el) {
//     $(el).slideUp();
//     $(el).attr("aria-expanded", "false");
//     $(el).addClass("bulletin__wrapper--closed");
//   }
// }

function isIE() {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}
