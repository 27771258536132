const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");

if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  updateIcons();
}

function updateIcons() {
  const icons = document.querySelectorAll(".ie__icon");

  const iconsArray = [].slice.call(icons);

  iconsArray.forEach(replaceElement);

  function replaceElement(svgElement) {
    const div = createDivFrom(svgElement);
    svgElement.parentNode.replaceChild(div, svgElement);
  }

  function createDivFrom(svgElement) {
    const icon = svgElement.getAttribute("data-icon");
    const size = svgElement.getAttribute("data-size");

    const elementClass = svgElement
      .getAttribute("class")
      .replace("ie__icon", "");

    // background-image: url(wp-content/themes/cassara-theme/assets/SVG/${icon}.svg);
    // background-size: fill;
    // background-repeat: no-repeat;
    // background-position: center;

    const styleString = `
      width: ${size}rem;
      height: ${size}rem;
      min-width: ${size}rem;
      min-height: ${size}rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    `;

    const div = document.createElement("div");
    const img = document.createElement("img");

    const imgSrc = `/wp-content/themes/cassara-theme/assets/SVG/${icon}.svg`;
    img.setAttribute("src", imgSrc);

    div.setAttribute("class", elementClass);
    div.setAttribute("style", styleString);
    if (svgElement.getAttribute("tabindex")) {
      div.setAttribute("tabindex", svgElement.getAttribute("tabindex"));
    }
    div.appendChild(img);
    return div;
  }
}
