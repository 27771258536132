import 'jquery-ui/ui/widgets/datepicker';

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const buttonImage = `${WP_DATA.THEME_URL}\/assets\/calendar.png`;

$(function() {
  initializePickers();
  initializeBlocks();

  $('#nav-book').on('click', bookingClicked);

  $('#open-booking').on('click', toggleWidget);

  window.addEventListener('scroll', scrollHandler);
});

function scrollHandler() {
  if ($('#booking-container').is(':visible')) {
    $('#booking-container').slideUp();
  }
}

function toggleWidget() {
  $('#booking-container').slideToggle();
}

function bookingClicked() {
  const base =
    'https://www.hilton.com/en/book/reservation/rooms/?flow=book&fromId=TheCassaraWeb&hotel=SANPPUP&inputModule=HOTEL_SEARCH&language=en';

  const url = base + stringDates() + flexibility() + numbers();
  window.open(url, '_blank');

  function stringDates() {
    const arr = getCurrent('arrival');
    const dep = getCurrent('departure');
    return `&arrivalDay=${arr.getDate()}&arrivalMonth=${arr.getMonth() +
      1}&arrivalYear=${arr.getFullYear()}&departureDay=${dep.getDate()}&departureMonth=${dep.getMonth() +
      1}&departureYear=${dep.getFullYear()}`;
  }

  function flexibility() {
    return '&flexibleDates=' + $('#flex').is(':checked');
  }

  function numbers() {
    const adults = $('#adults').val();
    const children = $('#children').val();
    const rooms = $('#rooms').val();
    return `&numAdults=${adults}&numChildren=${children}&numRooms=${rooms}`;
  }
}

function initializePickers() {
  $('#arrival').datepicker({
    showOn: 'button',
    buttonImage,
    buttonText: 'Select Arrival Date',
    minDate: '+0D',
    changeMonth: true,
    changeYear: true,
    onClose(selected) {
      const newArr = getCurrent('arrival');

      if (newArr > getCurrent('departure')) {
        const d = new Date(selected);
        d.setDate(d.getDate() + 1);
        $('#departure').datepicker('setDate', d);

        setBlock('departure');
      }

      setBlock('arrival');
    },
  });

  $('#arrival').datepicker('setDate', '0');

  $('#departure').datepicker({
    showOn: 'button',
    buttonImage,
    buttonText: 'Select Departure Date',
    minDate: '+1D',
    changeMonth: true,
    changeYear: true,
    onClose(selected) {
      const newDep = getCurrent('departure');

      if (newDep < getCurrent('arrival')) {
        const a = new Date(selected);
        a.setDate(a.getDate() - 1);
        $('#arrival').datepicker('setDate', a);

        setBlock('arrival');
      }

      setBlock('departure');
    },
  });

  $('#departure').datepicker('setDate', '1');
}

function initializeBlocks() {
  setBlock('arrival');
  setBlock('departure');
}

function setBlock(id) {
  const arr = getCurrent(id);
  const date = arr.getDate();
  const day = days[arr.getDay()];
  const month = months[arr.getMonth()];

  $(`#${id}-date`).text(date);
  $(`#${id}-day`).text(day);
  $(`#${id}-month`).text(month);
}

function getCurrent(id) {
  return $(`#${id}`).datepicker('getDate');
}
