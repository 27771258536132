// import $ from "jquery";

$(function() {
  const containers = $('.bulletin__wrapper');
  if (containers.length > 0) {
    containers.each((_, el) => {
      const close = $(el).find('.bulletin__close');
      close.on('keydown', (e) => {
        if (e.key === 'Enter') {
          remove(el);
        }
      });
      close.on('click', () => remove(el));
    });

    function remove(el) {
      $(el).slideUp();
      $(el).attr('aria-expanded', 'false');
      $(el).addClass('bulletin__wrapper--closed');
    }
  }
});
